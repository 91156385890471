<template>
  <div class="profile">
    <h1>Hello {{ authUser.displayName }}</h1>
    <p>
      Coming soon.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'profile',
  mounted() {
    document.title = "Profile - PocketLang.com"
  },
  computed: {
    ...mapGetters(['authUser'])
  },
}
</script>

<style scoped lang="scss">
.profile {
  margin: 0 .5rem;
}
</style>